/* Animation keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Updated gradient animation for text with proper browser support */
.hero-highlight {
  background: linear-gradient(to right, #01D6FD 0%, #007CFF 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  display: inline-block;
  position: relative;
  animation: shine 3s linear infinite;
}

/* Updated gradient animation for "Replace Them All" text */
@keyframes shine-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  60% {
    opacity: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(1, 214, 253, 0.3);
  }
  50% {
    text-shadow: 0 0 20px rgba(1, 214, 253, 0.5), 0 0 30px rgba(1, 214, 253, 0.3);
  }
  100% {
    text-shadow: 0 0 10px rgba(1, 214, 253, 0.3);
  }
}

/* Performance optimization for all animated elements */
.animated-element, 
.fade-in, 
.fade-in-up, 
.hero-title-animate,
.hero-description-animate,
.hero-cta-animate,
.trusted-by-animate,
.section-title,
.section-paragraph,
.motion-element,
.questions-container,
.answer-holder,
[data-aos] {
  will-change: transform, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* Animation classes */
.hero-title-animate,
.hero-description-animate,
.hero-cta-animate,
.trusted-by-animate {
  opacity: 0;
  animation: fadeIn 1.5s ease-out forwards;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.fade-in-up {
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

/* Motion component overrides */
.motion-element {
  opacity: initial !important;
  animation: initial !important;
  transform: initial !important;
  transition: all 0.5s ease !important;
}

/* Bounce animation styles */
.bounce-arrow {
  animation: bounce 2s infinite;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  bottom: -20px;
  width: fit-content;
}

.learn-more-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.arrow-icon {
  width: 40px;
  height: 40px;
  background: radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.bounce-arrow:hover .arrow-icon {
  transform: scale(1.1);
  background: radial-gradient(circle at 50% 200%, #3435393d, #17181a00 61%), radial-gradient(circle at 50% -60%, #34353970, #17181a00 75%);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Mobile optimizations */
@media (max-width: 767px) {
  .bounce-arrow {
    bottom: -10px;
  }
  
  /* Reduce animation durations on mobile */
  .hero-title-animate,
  .hero-description-animate,
  .hero-cta-animate,
  .trusted-by-animate {
    animation-duration: 1s;
  }
  
  .fade-in,
  .fade-in-up {
    animation-duration: 0.5s;
  }
  
  /* Simplify transitions on mobile */
  .motion-element {
    transition: all 0.3s ease !important;
  }
  
  /* Reduce animation complexity on mobile */
  @keyframes bounce {
    0%, 50%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    75% {
      transform: translateY(-3px);
    }
  }
} 